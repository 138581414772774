<template>
  <div>
    <!-- Filters -->
    <story-list-filters
        :role-filter.sync="roleFilter"
        :plan-filter.sync="planFilter"
        :status-filter.sync="statusFilter"
        :role-options="roleOptions"
        :plan-options="planOptions"
        :status-options="statusOptions"
        @filter="fetchStory"
    />

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
                @change="changePageStory({page:1 , itemsPerPage: $event})"
            />
            <label>entries</label>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative pb-3"
          :items="stories"
          :per-page="perPage"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.user.thumbnail"
                  :text="avatarText(data.item.name)"
                  :variant="`light-${resolveUserRoleVariant(data.item.user.role)}`"
                  :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }"
              />
            </template>
            <b-link
                :to="{ name: 'apps-users-view', params: { id: data.item.user.id } }"
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.name }}
            </b-link>
            <!--            <small class="text-muted">{{ data.item.name }}</small>-->
          </b-media>
        </template>
        <!-- Column: status -->
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.status }}</span>
          </div>
        </template>
        <!-- Column: created at -->
        <template #cell(created_at)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.created_at }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                :to="{ name: 'apps-story-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="data.item.status !== 'approved'"
                @click="changeStatus(data.item.id , {status: 'approved'})"
            >
              <feather-icon icon="CheckIcon"/>
              <span class="align-middle ml-50">Approve it</span>
            </b-dropdown-item>

            <b-dropdown-item
                v-if="data.item.status !== 'rejected'"
                @click="changeStatus(data.item.id , {status: 'rejected'})"
            >
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">Reject it</span>
            </b-dropdown-item>

            <!--            <b-dropdown-item-->
            <!--              :to="{ name: 'apps-story-edit', params: { id: data.item.id } }"-->
            <!--            >-->
            <!--              <feather-icon icon="EditIcon" />-->
            <!--              <span class="align-middle ml-50">Edit</span>-->
            <!--            </b-dropdown-item>-->

            <b-dropdown-item @click="deleteStory(data.item.id)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
                class="text-muted"
            >Showing {{ pagination.pageStart }} to {{ pagination.pageStop }} of
              {{ pagination.itemsLength }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="pagination.itemsLength"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePageStory({ page: $event , itemsPerPage: perPage})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import {ref, onUnmounted, onMounted} from '@vue/composition-api'
import {avatarText} from '@core/utils/filter'
import StoryRepository from '@/abstraction/repository/storyRepository'
import StoryListFilters from './StoryListFilters.vue'
import useStoryList from './useStoryList'
import userStoreModule from '../user/userStoreModule'
// repository
const storyRepository = new StoryRepository()
export default {
  components: {
    StoryListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      form: {
        description: null,
      },
    }
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Author',
        value: 'author',
      },
      {
        label: 'Editor',
        value: 'editor',
      },
      {
        label: 'Maintainer',
        value: 'maintainer',
      },
      {
        label: 'Subscriber',
        value: 'subscriber',
      },
    ]

    const planOptions = [
      {
        label: 'Basic',
        value: 'basic',
      },
      {
        label: 'Company',
        value: 'company',
      },
      {
        label: 'Enterprise',
        value: 'enterprise',
      },
      {
        label: 'Team',
        value: 'team',
      },
    ]

    const statusOptions = [
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
    ]

    const {
      fetchStory,
      changeStatus,
      tableColumns,
      perPage,
      pagination,
      currentPage,
      totalUsers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      stories,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useStoryList()

    onMounted(async () => {
      await fetchStory()
    })

    const changePageStory = paginate => {
      fetchStory(paginate)
    }
    return {
      // Sidebar
      fetchStory,
      stories,
      pagination,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      changePageStory,
      changeStatus,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async deleteStory(storyId) {
      await storyRepository.delete(storyId)
      const index = this.stories.findIndex(x => x.id === storyId)
      this.$delete(this.stories, index)
    },
    shortDescription(value) {
      const words = value.split(' ')
      const wordsSliced = words.slice(0, 9)
      if (words.length > 10) {
        return `${wordsSliced.join(' ')}...`
      }
      return wordsSliced.join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
  color: $red;
}
</style>
